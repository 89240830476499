import { isDefined } from "../../config/helpers";
import Script from 'next/script';

const AppSchema = ({ breadcrumbProps }) => {
    var breadcrumbStr = "";
    if (isDefined(breadcrumbProps)) {
        const { last, links } = breadcrumbProps;
        Object.entries(links).map((key, index, other, one) => {
            const route = key[0];
            breadcrumbStr = breadcrumbStr + route + " > ";
        });
        breadcrumbStr += last;
        const appJsonld = {
            __html: JSON.stringify({
                "@context": "https://schema.org",
                "@type": "WebPage",
                breadcrumb: breadcrumbStr,
            }),
        };
        return (
            <Script
                type="application/ld+json"
                dangerouslySetInnerHTML={appJsonld}
                id="app-jsonld"
                key="app-jsonld"
            />
        );
    }
    return <></>;
};

export default AppSchema;
